.investmentssec{
    img {
        width: 100%;
        height: auto;
        max-width: 500px !important;
        filter: hue-rotate(45deg);
        &.erngimg {
            max-width: 320px;
            padding: 20px;
        }
    }
}