.strategies {
  padding: 100px 0;
}
.strategies .lftsec {
  position: sticky;
  top: 120px;
  align-self: flex-start;
  transition: all 0.3s ease-in-out;
  overflow-x: visible !important;
}
.strategies .left-text h2 {
  font-size: 40px;
  margin-bottom: 10px;
}
.strategies .left-text span {
  color: var(--primary-color);
}
.strategies .right-content {
  position: relative;
}
.strategies .right-content .row {
  row-gap: 20px;
}
.strategies .outwrapbox {
  background-image: url(../../Assets/images/strategies1.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 470px;
  border: 1px solid rgba(9, 34, 78, 0.94);
  border-radius: 12px;
  transition: all 0.3s;
  border-radius: 10px;
  filter: hue-rotate(45deg);
  cursor: pointer;
}
.strategies .outwrapbox1 {
  background-image: url(../../Assets/images/strategies2.jpg);
}
.strategies .outwrapbox2 {
  background-image: url(../../Assets/images/strategies3.jpg);
}
.strategies .outwrapbox3 {
  background-image: url(../../Assets/images/strategies4.jpg);
}
.strategies .outwrapbox4 {
  background-image: url(../../Assets/images/strategies5.jpg);
}
.strategies .outwrapbox5 {
  background-image: url(../../Assets/images/strategies6.jpg);
}
.strategies .outwrapbox6 {
  background-image: url(../../Assets/images/strategies7.jpg);
}
.strategies .subtext {
  margin-bottom: 20px;
  text-align: justify;
}

.strategybox {
  height: 470px;
  color: #fff;
}

.layerbox {
  text-align: left;
  background-color: transparent;
  background-image: radial-gradient(at top right, rgba(198, 161, 205, 0.438) 0%, #000000 76%);
  padding: 80px 30px 0px 30px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  transition: all 0.3s;
  border-radius: 10px 10px 10px 10px;
  height: 470px;
}

.iconbox {
  position: relative;
}
.iconbox .icon {
  color: #2edaf1;
  margin: 0px 0px 190px 0px;
  padding: 0px 0px 0px 0px;
  opacity: 0;
  transition: all 0.3s;
}
.iconbox svg {
  width: 55px;
  font-size: 65px;
  height: auto;
}

.mbview {
  max-width: 200px;
}

.right-content {
  position: relative;
}

@media screen and (max-width: 992px) {
  .lftsec {
    position: relative !important;
    top: 0px !important;
  }
}
@media screen and (max-width: 575px) {
  .strategies {
    padding: 100px 0;
  }
  .strategies .left-text h2 {
    font-size: 26px;
  }
  .layerbox {
    text-align: center;
    padding: 15px 15px 0px 15px;
    height: 500px !important;
  }
  .row {
    --bs-gutter-x: 0rem !important;
  }
  .outwrapbox {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 500px !important;
    border: 1px solid rgba(255, 255, 255, 0.1607843137);
    border-radius: 12px;
    transition: all 0.3s;
    border-radius: 10px;
    cursor: pointer;
  }
  .strategybox {
    height: 500px !important;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}/*# sourceMappingURL=Strategies.css.map */