.strategies {
    padding: 100px 0;
    .lftsec{
      position: sticky;
      top: 120px;
      align-self: flex-start;
      transition: all 0.3s ease-in-out;
      overflow-x: visible !important;
    }
    .left-text{
    
      h2{
        font-size: 40px;
        margin-bottom: 10px;
      }
      span{
        color: var(--primary-color);
      }
    }
    .right-content{
      position: relative;
      .row{
        row-gap:20px;
      }
    }
    .outwrapbox{
      background-image: url(../../Assets/images/strategies1.jpg);
      background-size: cover;
      background-position: center center; 
      background-repeat: no-repeat;
      height:470px; 
      border: 1px solid rgb(9 34 78 / 94%);
      border-radius: 12px;
      transition: all 0.3s;
      border-radius: 10px;
      filter: hue-rotate(45deg);
      cursor: pointer;
      // &:hover .layerbox{
      //   background-image: linear-gradient(208deg, #4f2c5383 0%, #1f122c8a 100%);
      //   padding: 40px 30px 40px 30px;
    // }
  
   
    }
    .outwrapbox1{
      background-image: url(../../Assets/images/strategies2.jpg);
    }
    .outwrapbox2{
      background-image: url(../../Assets/images/strategies3.jpg);
    }
    .outwrapbox3{
      background-image: url(../../Assets/images/strategies4.jpg);
    }
    .outwrapbox4{
      background-image: url(../../Assets/images/strategies5.jpg);
    }
    .outwrapbox5{
      background-image: url(../../Assets/images/strategies6.jpg);
    }
    .outwrapbox6{
      background-image: url(../../Assets/images/strategies7.jpg);
    }
    .subtext{
      margin-bottom: 20px;
      text-align: justify;
    }
  }
  .strategybox {
    height: 470px;
    color: #fff;
  }
  .layerbox{
    text-align: left;
    background-color: transparent;
    background-image: radial-gradient(at top right, rgba(198, 161, 205, 0.438) 0%, #000000 76%);
    padding: 80px 30px 0px 30px;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    transition: all 0.3s;
    border-radius: 10px 10px 10px 10px;
    height: 470px;
  }
  .iconbox{
    position: relative;
    .icon{
      color: #2edaf1;
      margin: 0px 0px 190px 0px;
      padding: 0px 0px 0px 0px;
      opacity: 0;
      transition: all 0.3s;
     
    }
    svg{
      width: 55px;
      font-size: 65px;
      height:auto;
    }
  
  
  }
 
  .mbview{
    max-width: 200px;
  }
  
 
    
  .right-content {
position: relative;

    
  }
  
  // .scrollable-content {
  //   overflow-y: auto;
  //   overflow-x: hidden;
  //   max-height: 550px; 
  // }
  
  @media screen and (max-width: 992px){
    .lftsec{
      position: relative !important;
      top: 0px !important;
    
    }
  }
  @media screen and (max-width: 575px){
    .strategies {
      padding: 100px 0;
      .left-text{
        
        h2{
          font-size: 26px;
       
        }

    }
  }
  .layerbox {
    text-align: center;
   
    padding: 15px 15px 0px 15px;
    height: 500px !important;
    
}
.row {
  --bs-gutter-x: 0rem !important;
  }
  // .layerbox{
  //   text-align: left;
  //   background-color: transparent;
  //   background-image: radial-gradient(at top right, #94e1ff48 0%, #00000052 76%);
  //   padding: 10px 10px 0px 10px;
  //   border-style: solid;
  //   border-width: 0px 0px 0px 0px;
  //   transition: all 0.3s;
  //   border-radius: 10px 10px 10px 10px;
  //   height: 500px;
  // }
  .outwrapbox{

    background-size: cover;
    background-position: center center; 
    background-repeat: no-repeat;
    height:500px !important;
    border: 1px solid #ffffff29 ;
    border-radius: 12px;
    transition: all 0.3s;
    border-radius: 10px;
    cursor: pointer;
  //   &:hover .layerbox{
  //     background-image: transparent;
  //     padding: 10px 10px 10px 10px;
  // }
  //   &:hover .icon{
  //    opacity: 0;
  // }
  }
  .strategybox {
    height: 500px !important;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}