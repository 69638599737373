.about-section .about-thumb {
    position: relative;
    left: 90px;
}
.aboutvtnx{
    padding-top: 50px;
}

.about-section .shape1 {
    position: absolute;
    right: 196px;
    top: 113px;
}

.about-section .shape2 {
    position: absolute;
    top: 178px;
    left: -117px;
}

.about-section .shape3 {
    position: absolute;
    bottom: 160px;
    right: 137px;
}
.dreamit-section-title{
    margin-bottom: 50px;
}

/*section title*/
.dreamit-section-sub-title h5 {
    font-size: 18px;
    margin: 0 0 12px;
    font-weight: 300;
    position: relative;
    left: 72px;
    z-index: 1;
    display: inline-block;
}

.dreamit-section-sub-title h5:before {
    position: absolute;
    content: "";
    top: 17px;
    left: -73px;
    height: 2px;
    width: 59px;
    background: #0093FA;
}

.dreamit-section-main-title h2 {
    font-size: 42px;
    margin: 0 0 22px;
}

.dreamit-section-content-text p {
    text-align: justify;
}

/*about right side*/
.about-icon-thumb {
    height: auto;
    width: 100%;
    flex: 0 0 61px;
    max-width: 61px;
    line-height: 1.2;
    text-align: center;
    border-radius: 100%;
    transition: .5s;
    animation: swing ease-in-out 1s infinite alternate;
}
@keyframes swing {
    0% { transform: rotate(5deg); }
    100% { transform: rotate(-10deg); }
}

.aboutIcons .about-icon-thumb img {
    width: auto;
    height: auto;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    // filter: hue-rotate(153deg);
    
}

.about-content h2 {
    font-size: 26px;
    margin: 25px 0 12px;
}


.about-btn a {
    display: inline-block;
    background: #37A3FE;
    padding: 17px 39px;
    font-size: 15px;
    border-radius: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: .5s;
    font-weight: 600;
}

.about-btn a:hover {
    color: #37A3FE;
}

.about-btn a:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    transition: .5s;
    z-index: -1;
    background: #fff;
    border-radius: 30px;
}

.about-btn a:hover:after {
    left: 0;
    width: 100%;
}

/*about section style two*/
.about-section.style-two {
    background: #fff;
    margin-top: -175px;
    position: relative;
}

.about-section.style-two .about-content p {
    color: #616161;
}

.about-section.style-two .about-content h2 {
    color: #081B5F;
}

.about-section.style-two .dreamit-section-main-title h2 {
    color: #081B5F;
}

.about-section.style-two .dreamit-section-sub-title h5 {
    color: #081B5F;
}

.about-section.style-two .dreamit-section-content-text p {
    color: #616161;
}

.about-section.style-two .about-btn a {
    border: 1px solid#37A3FE;
}
.membership-title-2 {
    position: relative;
    z-index: 1;
    width: 100%;
 max-width: auto;
    margin-right: auto;
    // margin-bottom: 91px;
    margin-left: auto;
    padding: 30px 42px;
    background-position: 50% 50%;
    border-bottom: 0px solid #f54097;
    border-radius: 14px;
    background-color: transparent;
    background-image: url(../../Assets/images/Frame\ 1.png);
    // background-position: 0px 0px;
    background-size: cover;
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    filter: hue-rotate(299deg);
  
}
/*about style three*/
.about-section.style-three {
    // background: url(assets/images/new/bg.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 1;
    // padding-bottom: 100px;
}

.about-section.style-three .dreamit-section-content-text p {
 
    text-align: center;
    margin: 0 auto 0;
}

.about-section.style-three .dreamit-section-title.text-center .dreamit-section-main-title h2 {
    font-size: 36px;
    margin: 0 0 22px;
    font-weight: 600;
    
}

/*left side*/
.about-section.style-three .about-thumb {
    position: relative;
    left: 0;
    top: 22px;
}

/*right side*/
.about-section.style-three .about-single-icon-box {
    margin-bottom: 35px;
}

.about-section.style-three .about-icon-thumb {
    margin-right: 35px;
}

.about-section.style-three .about-content h2 {
    font-size: 20px;
    margin: 0px 0 0px;
    font-weight: 500;
}

.about-section.style-three .about-shape {
    position: absolute;
    top: -10px;
    right: 0px;
}

.about-section.style-three .about-shape img {
    background-color: #73bdff;
    filter: brightness(3);
    width: 2px;
    height: 93%;
    margin-right: 4px;
    opacity: 0.3;
}

/*about section five */
.about-section.style-five .dreamit-content-text-inner p {
    width: 99%;
}

.dreamit-section-title.two .dreamit-section-sub-title h5 {
    margin: 0 0 33px;
}

.dreamit-section-title.two .dreamit-section-sub-title h5 {
    left: 29px;
}

.dreamit-section-title.two .dreamit-section-sub-title h5 img {
    margin-right: 9px;
}

.dreamit-section-title.two .dreamit-section-sub-title h5 {
    background: #00223e;
    padding: 11px 29px;
    left: 0;
    border-radius: 4px;
}

.dreamit-section-title.two .dreamit-section-sub-title h5::before {
    display: none;
}

.dreamit-section-title.two .dreamit-section-main-title h2 {
    font-size: 36px;
    margin: 0 0 22px;
    font-weight: 600;
}

.dreamit-section-title.two.text-center .dreamit-section-sub-title h5::after {
    display: none;
}
.nfts-box-line {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 1px;
    height: 100%;
    background-image: linear-gradient(82deg, rgba(15, 201, 241, 0), #09eeff 10%, rgba(15, 201, 241, 0));
}








@media (min-width: 1169px) and (max-width: 1364px) {
    
    .about-section .shape3 {
        right: 73px;
    }

    .about-section .shape1 {
        right: 113px;
    }
    .about-section.style-two .about-thumb {
        left: 32px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1168px) {
    .about-section .shape1 {
        right: 115px;
    }

    .about-section .shape3 {
        right: 76px;
    }
    .about-section.style-two .about-thumb {
        left: 40px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-thumb {
        left: -38px;
    }
    .about-section .shape1 {
        right: 0;
    }

    .about-section .shape3 {
        right: -54px;
    }
    .about-section.style-two .about-thumb {
        left: -76px;
    }
    .about-section.style-three .about-thumb {
        left: -100px;
    }
    .about-section.style-three .dreamit-section-title.two .dreamit-section-content-text p {
        width: 65%;
        text-align: center;
        margin: auto;
    }

    .about-section.style-three .dreamit-section-title.two .dreamit-section-sub-title h5:before {
        left: -7px;
    }
    
    .about-section.style-three .text-center {
        text-align: center !important;
    }
}


@media only screen and (min-width: 600px) and (max-width:767px) {
    .about-section .about-thumb {
        left: 0;
        margin-bottom: 30px;
    }
    .about-section.pb-100 {
        padding-bottom: 60px;
    }

}
@media only screen and (min-width:481px) and (max-width: 599px) {
    .about-section .shape1 {
        right: 65px;
    }
    .about-section .about-thumb {
        margin-bottom: 40px;
    }   
    .about-section.pb-100 {
        padding-bottom: 60px;
    }
    .about-section.style-two.pt-260 {
        padding-top: 173px;
    }

}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .about-section .about-thumb {
        left: 0;
        margin-bottom: 40px;
    }

    .about-section .shape1 {
        right: -17px;
    }

    .about-section .shape3 {
        right: -13px;
    }

    .about-section .shape2 {
        left: 0;
    }
    
    .about-section.pb-100 {
        padding-bottom: 60px;
    }
    .about-section.style-two.pt-260 {
        padding-top: 217px;
    }
    .about-section.style-three .dreamit-section-title.text-center .dreamit-section-main-title h2 {
        font-size: 33px;
    }

    .about-section.style-three .about-thumb {
        left: -63px;
        top: 22px;
    }
    
    .about-section.style-three .about-icon-thumb {
        margin-right: 19px;
    }
}

@media only screen and (max-width: 767px) {

    .about-section.style-three .about-single-icon-box {
        margin-bottom: 20px;
    }

    .about-section.style-three .about-content h2 {
        font-size: 16px;
        margin: 0px 0 0px;
        font-weight: 500;
    }
    .road-map .dreamit-section-main-title h2,
    .about-section.style-three .dreamit-section-title.text-center .dreamit-section-main-title h2 {
        font-size: 32px;
    }
}

@media screen and (max-width: 575px) {
    .membership-title-2 {
     
        padding: 71px 1px;
       
    }
}