

.advsec{
    padding-top: 50px;
    padding-bottom: 50px;
    
.about-content h1 {
    font-size: 16px;
    margin: 12px 0 12px;
}
.about-icon-thumb{
    height: 100px;
}
.about-content{
    padding-left: 10px;
}
}