.platformsec{
    padding-top: 50px;
    padding-bottom: 50px;
    img {
        width: 100%;
        height: auto;
        max-width: 450px !important;
        filter: hue-rotate(126deg);
        &.erngimg {
            max-width: 320px;
            padding: 20px;
        }

    }
}