.earningtxt {
  padding-left: 10px;
  padding-right: 10px;
}

.upgrade-card {
  min-height: 200px;
  background-color: #3218a4;
  background-image: linear-gradient(302deg, rgba(199, 122, 239, 0.9411764706) -12%, rgba(2, 3, 6, 0.6));
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 30px 50px;
  display: flex;
  border: 1px solid rgba(117, 117, 243, 0.6196078431);
}

.card {
  width: 100%;
  min-height: 200px;
  background-image: linear-gradient(58deg, #0a1424 75%, rgba(137, 24, 164, 0.55));
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 30px;
  display: flex;
  border: 1px solid rgba(94, 94, 237, 0.2392156863);
}

.card.red {
  width: 100%;
  min-height: 200px;
  background-image: linear-gradient(58deg, #0a1424 75%, rgba(137, 24, 164, 0.55));
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 30px;
  display: flex;
  border: 1px solid rgba(94, 94, 237, 0.2392156863);
}

.card.purple {
  width: 100%;
  min-height: 200px;
  background-image: linear-gradient(58deg, #0a1424 75%, rgba(137, 24, 164, 0.55));
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 30px;
  display: flex;
  border: 1px solid rgba(94, 94, 237, 0.2392156863);
}

.backup-card {
  width: 100%;
  max-width: 450px;
  background-color: #33116c;
  background-image: radial-gradient(circle farthest-side at 50% 0, rgba(51, 35, 84, 0.9803921569) 28%, #8d4e8a 84%);
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 60px 30px 50px;
  display: flex;
  border: 1px solid rgba(126, 126, 217, 0.4117647059);
}

.storage-card {
  min-height: 340px;
  grid-column-gap: 20px;
  background-image: linear-gradient(45deg, #292b42 85%, #6c5ee3);
  border-radius: 20px;
  align-items: center;
  padding: 30px 20px;
  display: flex;
  background-color: #180b4e;
  background-image: linear-gradient(302deg, rgba(179, 109, 215, 0.6509803922) -12%, rgba(2, 3, 6, 0.6));
}/*# sourceMappingURL=Earning.css.map */