.trad {
  filter: hue-rotate(45deg);
}
.trad img {
  width: 100%;
  height: auto;
  max-width: 400px;
}
.trad img.erngimg {
  max-width: 320px;
  padding: 20px;
}/*# sourceMappingURL=Trading.css.map */