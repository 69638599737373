.earningtxt{
    padding-left: 10px;
    padding-right: 10px;
}
// .earningsec{
//    padding-top: 50px;
// }

.upgrade-card {
    min-height: 200px;
    background-color: #3218a4;
    background-image: linear-gradient(302deg, #c77aeff0 -12%, rgb(2 3 6 / 60%));
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 30px 50px;
    display: flex;
    border: 1px solid #7575f39e;
}
.card {
    width: 100%;
    min-height: 200px;
    background-image: linear-gradient(58deg, #0a1424 75%, rgb(137 24 164 / 55%));
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 20px 30px;
    display: flex;
    border: 1px solid #5e5eed3d;
}
.card.red {
    width: 100%;
    min-height: 200px;
    background-image: linear-gradient(58deg, #0a1424 75%, rgb(137 24 164 / 55%));
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 20px 30px;
    display: flex;
    border: 1px solid #5e5eed3d;
}
.card.purple {
    width: 100%;
    min-height: 200px;
    background-image: linear-gradient(58deg, #0a1424 75%, rgb(137 24 164 / 55%));
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 20px 30px;
    display: flex;
    border: 1px solid #5e5eed3d;
}
.backup-card {
    width: 100%;
    max-width: 450px;
    background-color: #33116c;
    background-image: radial-gradient(circle farthest-side at 50% 0, #332354fa 28%, #8d4e8a 84%);
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px 30px 50px;
    display: flex;
    border: 1px solid #7e7ed969;
}
.storage-card {
    min-height: 340px;
    grid-column-gap: 20px;
    background-image: linear-gradient(45deg, #292b42 85%, #6c5ee3);
    border-radius: 20px;
    align-items: center;
    padding: 30px 20px;
    display: flex;
    background-color: #180b4e;
    background-image: linear-gradient(302deg, #b36dd7a6 -12%, rgb(2 3 6 / 60%));
}