.advsec {
  padding-top: 50px;
  padding-bottom: 50px;
}
.advsec .about-content h1 {
  font-size: 16px;
  margin: 12px 0 12px;
}
.advsec .about-icon-thumb {
  height: 100px;
}
.advsec .about-content {
  padding-left: 10px;
}/*# sourceMappingURL=Advantages.css.map */