.platformsec {
  padding-top: 50px;
  padding-bottom: 50px;
}
.platformsec img {
  width: 100%;
  height: auto;
  max-width: 450px !important;
  filter: hue-rotate(126deg);
}
.platformsec img.erngimg {
  max-width: 320px;
  padding: 20px;
}/*# sourceMappingURL=Platform.css.map */