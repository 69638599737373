.topmainsecai {
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.topmainsecai .infobox {
  position: relative;
}

.topmainsecai .topmainvideo {
  margin-top: 50px;
  transition: 1s linear;
  max-width: 800px;
  width: 100%;
  height: auto;
}

.topmainimg {
  transition: 1s linear;
}

.topmainsecai .infotext {
  position: relative;
  max-width: 700px;
  margin: 0 auto;
}
.topmainsecai .infotext h1 {
  font-size: 45px;
}

.topmainsecai .infotext h2 {
  text-align: center;
  text-transform: capitalize;
}

.spn {
  display: inline-block;
  background-image: linear-gradient(128deg, #f54097, #3f26d9);
  text-shadow: none;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .bodyrobotSticky.left::after {
    transform: translateX(-160%) scale(0.5);
    bottom: -15px;
} *//*# sourceMappingURL=Banner.css.map */