body.Dark .keybox {
  background-color: #fff;
  border-color: #000;
  background-image: unset;
}

.keyfeature {
  padding: 70px 0;
}
.keyfeature h2 {
  margin-top: -90px;
  margin-bottom: 65px;
  font-weight: 600;
  font-size: 45px;
}
.keyfeature h3 {
  font-size: 1.55rem;
}
.keyfeature p {
  margin-top: -15px !important;
}
.keyfeature .row {
  gap: 15px 0;
}
.keyfeature .keyboxwrap {
  margin-bottom: 15px;
  height: 100%;
}
.keyfeature .keybox {
  text-align: center;
  background-color: transparent;
  background-image: radial-gradient(at top right, rgba(205, 148, 255, 0.27) 0%, #000 76%);
  padding: 10px 20px 0px 20px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(255, 255, 255, 0.1607843137);
  border-radius: 20px 20px 20px 20px;
  height: 270px;
}

@media (max-width: 1400px) {
  .aboutsec, .tradesec, .investment {
    padding: 60px 0;
  }
  .keyfeature {
    padding: 60px 0;
  }
  .keyfeature h2 {
    margin-top: -30px;
  }
  .strategies {
    padding: 60px 0;
  }
  h2 {
    font-size: 36px;
  }
}
@media (max-width: 1200px) {
  .keyfeature .keyicon {
    text-align: center;
  }
  .key-body {
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .banner-area {
    padding: 40px 0;
  }
  .aboutsec, .tradesec, .investment {
    padding: 40px 0;
  }
  .keyfeature {
    padding: 40px 0;
  }
  .keyfeature h2 {
    margin-top: -30px;
  }
  .strategies {
    padding: 40px 0;
  }
  h2 {
    font-size: 36px;
  }
  .left-accordion {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .right-accordion {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .banner-area {
    padding: 30px 0;
  }
  .aboutsec, .tradesec, .investment {
    padding: 30px 0;
  }
  .keyfeature {
    padding: 30px 0;
  }
  .keyfeature h2 {
    margin-top: -30px;
  }
  .strategies {
    padding: 30px 0;
  }
  h2 {
    font-size: 36px;
  }
  .faqSec {
    padding: 0px 0;
  }
  .faqSec h2 {
    margin-bottom: 30px;
  }
}/*# sourceMappingURL=Keyfeatures.css.map */