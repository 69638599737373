@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");
body {
  background-color: #000;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/2317755_Business_Infographics_Stock\ Market_1280x720.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -3;
  opacity: 0.2;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
@font-face {
  font-family: "vitnixxRegular";
  src: url("../fonts/vitnixxRegular.woff2") format("woff2"), url("../fonts/vitnixxRegular.woff") format("woff"), url("../fonts/vitnixxRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@keyframes colorChange {
  0% {
    filter: hue-rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes animate {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-reverse {
  0% {
    transform: rotate(360deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes hue {
  0%, 100% {
    filter: hue-rotate(250deg) brightness(1.3);
  }
  50% {
    filter: hue-rotate(300deg) brightness(1.3);
  }
}
@keyframes vx_animated-text {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 1;
    text-shadow: 0 0 25px #f54097;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes vx_animated-text2 {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 1;
    text-shadow: 0 0 25px #10181f;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
/* Animation */
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
/* RoadMap */
* {
  box-sizing: border-box;
}

.timeline {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  position: relative;
}
.timeline__event {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  margin: 20px 0;
  border-radius: 6px;
  align-self: center;
  width: 50vw;
}
.timeline__event:nth-child(2n+1) {
  flex-direction: row-reverse;
}
.timeline__event:nth-child(2n+1) .timeline__event__date {
  border-radius: 0 6px 6px 0;
}
.timeline__event:nth-child(2n+1) .timeline__event__content {
  border-radius: 6px 0 0 6px;
}
.timeline__event:nth-child(2n+1) .timeline__event__icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #3a567e;
  position: absolute;
  top: 0%;
  left: 50%;
  right: auto;
  z-index: -1;
  transform: translateX(-50%);
  animation: fillTop 2s forwards 4s ease-in-out;
}
.timeline__event:nth-child(2n+1) .timeline__event__icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #3a567e;
  position: absolute;
  right: 0;
  z-index: -1;
  top: 50%;
  left: auto;
  transform: translateY(-50%);
  animation: fillLeft 2s forwards 4s ease-in-out;
}
.timeline__event__title {
  font-size: 15px;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.5px;
}
.timeline__event__content {
  padding: 20px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  background: #363333;
  width: calc(40vw - 84px);
  border-radius: 0 6px 6px 0;
}
.timeline__event__date {
  color: linear-gradient(289.89deg, #dec7ff -23.71%, #5c27fe 96.09%);
  font-size: 20px;
  font-weight: 600;
  background: #c34283;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 20px;
  border-radius: 6px 0 0 6px;
  color: #fff;
}
.timeline__event__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c34283;
  padding: 20px;
  align-self: center;
  margin: 0 20px;
  background: #3a567e;
  border-radius: 100%;
  width: 40px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  padding: 40px;
  height: 40px;
  position: relative;
}
.timeline__event__icon i {
  font-size: 32px;
}
.timeline__event__icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #3a567e;
  position: absolute;
  top: 0%;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  animation: fillTop 2s forwards 4s ease-in-out;
}
.timeline__event__icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #3a567e;
  position: absolute;
  left: 0%;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  animation: fillLeftOdd 2s forwards 4s ease-in-out;
}
.timeline__event__description {
  flex-basis: 100%;
}
.timeline__event--type2:after {
  background: #202d8f;
}
.timeline__event--type2 .timeline__event__date {
  color: #fff;
  background: #202d8f;
}
.timeline__event--type2:nth-child(2n+1) .timeline__event__icon:before, .timeline__event--type2:nth-child(2n+1) .timeline__event__icon:after {
  background: #c59d3d;
}
.timeline__event--type2 .timeline__event__icon {
  background: #c59d3d;
  color: #202d8f;
}
.timeline__event--type2 .timeline__event__icon:before, .timeline__event--type2 .timeline__event__icon:after {
  background: #c59d3d;
}
.timeline__event--type2 .timeline__event__title {
  color: #fff;
}
.timeline__event--type3:after {
  background: #afa6b7;
}
.timeline__event--type3 .timeline__event__date {
  color: #fff;
  background-color: #afa6b7;
}
.timeline__event--type3:nth-child(2n+1) .timeline__event__icon:before, .timeline__event--type3:nth-child(2n+1) .timeline__event__icon:after {
  background: #aff1b6;
}
.timeline__event--type3 .timeline__event__icon {
  background: #aff1b6;
  color: #afa6b7;
}
.timeline__event--type3 .timeline__event__icon:before, .timeline__event--type3 .timeline__event__icon:after {
  background: #aff1b6;
}
.timeline__event--type3 .timeline__event__title {
  color: #fff;
}
.timeline__event:last-child .timeline__event__icon:before {
  content: none;
}

.position-relative {
  position: relative;
}

.services__wrapper.aboutusimg > div {
  margin-left: auto;
  margin-right: auto;
  width: auto !important;
  height: auto !important;
  max-width: 420px;
}

.services__wrapper.aboutusimg > div video {
  mix-blend-mode: difference;
}

.services__wrapper.aboutusimg > div::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0px 0px 310px 350px #000000;
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
    align-self: center;
    width: 100%;
  }
  .timeline__event__content {
    width: 100%;
  }
  .timeline__event__icon {
    border-radius: 6px 6px 0 0;
    width: 100%;
    margin: 0;
    box-shadow: none;
  }
  .timeline__event__icon:before, .timeline__event__icon:after {
    display: none;
  }
  .timeline__event__date {
    border-radius: 0;
    padding: 20px;
  }
  .timeline__event:nth-child(2n+1) {
    flex-direction: column;
    align-self: center;
    width: 100%;
  }
  .timeline__event:nth-child(2n+1) .timeline__event__date {
    border-radius: 0;
    padding: 20px;
  }
  .timeline__event:nth-child(2n+1) .timeline__event__icon {
    border-radius: 6px 6px 0 0;
    margin: 0;
  }
  .timeline__event--type2 .timeline__event__icon {
    display: none;
  }
  .timeline__event__icon {
    display: none;
  }
  .timeline__event--type3 .timeline__event__icon {
    display: none;
  }
}
@keyframes fillLeft {
  100% {
    right: 100%;
  }
}
@keyframes fillTop {
  100% {
    top: 100%;
  }
}
@keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}
*,
::after,
::before {
  box-sizing: border-box;
}

body {
  font-family: "Comfortaa", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #adb0bc;
  line-height: 1.7;
  background-color: #000;
  word-wrap: break-word;
  padding: 0px;
  margin: 0px;
}

.row {
  margin-right: auto !important;
  margin-left: auto !important;
}

.img {
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
  width: auto;
  height: auto;
}

img {
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
  width: auto;
  height: auto;
}

a {
  color: rgba(245, 64, 151, 0.3490196078);
  outline: 0;
  text-decoration: none;
  transition: all 0.3s ease-out 0s;
}
a:focus {
  text-decoration: none;
  outline: 0;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
a:hover {
  color: rgba(245, 64, 151, 0.3490196078);
  text-decoration: none;
}

button {
  color: rgba(245, 64, 151, 0.3490196078);
  outline: 0;
  text-decoration: none;
  transition: all 0.3s ease-out 0s;
  font-family: "Comfortaa", sans-serif;
  font-weight: bold;
}
button:hover {
  color: rgba(245, 64, 151, 0.3490196078);
  text-decoration: none;
}
button:focus {
  outline: 0;
}

.loaderx {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 99;
}

.loader__layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: transparent;
  z-index: 100;
}

.loader__container {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ffffff;
  z-index: 101;
}

.loader__container__counter {
  font-size: 170px;
  font-weight: 700;
  margin: 0;
}

.loader__container__loading {
  font-size: 50px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 10px;
}

.btn:focus {
  text-decoration: none;
  outline: 0;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.button:focus {
  text-decoration: none;
  outline: 0;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

input:focus {
  outline: 0;
}

textarea {
  outline: 0;
}
textarea:focus {
  outline: 0;
}

h1 {
  font-family: "Comfortaa", sans-serif;
  color: #ffffff;
  margin-top: 0;
  font-weight: 700;
  line-height: 1.3;
  text-transform: capitalize;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}
h1 a {
  color: inherit;
}

h2 {
  font-family: "Comfortaa", sans-serif;
  color: #ffffff;
  margin-top: 0;
  font-weight: 700;
  line-height: 1.3;
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: bold;
}
h2 a {
  color: inherit;
}

h3 {
  font-family: "Comfortaa", sans-serif;
  color: #ffffff;
  margin-top: 0;
  font-weight: 700;
  line-height: 1.3;
  text-transform: capitalize;
  font-size: 1.75rem;
  font-weight: bold;
}
h3 a {
  color: inherit;
}

h4 {
  font-family: "Comfortaa", sans-serif;
  color: #ffffff;
  margin-top: 0;
  font-weight: 700;
  line-height: 1.3;
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: bold;
}
h4 a {
  color: inherit;
}

h5 {
  font-family: "Comfortaa", sans-serif;
  color: #ffffff;
  margin-top: 0;
  font-weight: 700;
  line-height: 1.3;
  text-transform: capitalize;
  font-size: 1.25rem;
  font-weight: bold;
}
h5 a {
  color: inherit;
}

h6 {
  font-family: "Comfortaa", sans-serif;
  color: #ffffff;
  margin-top: 0;
  font-weight: 700;
  line-height: 1.3;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: bold;
}
h6 a {
  color: inherit;
}

.list-wrap {
  margin: 0;
  padding: 0;
}
.list-wrap li {
  list-style: none;
}

p {
  font-family: "Comfortaa", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #fff;
  margin-top: 0px;
  margin-bottom: 15px;
}

hr {
  border-bottom: 1px solid #0f161b;
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #0f161b;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #0f161b;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(245, 64, 151, 0.3490196078);
}

::-moz-selection {
  background: #f54097;
  color: #0f161b;
  text-shadow: none;
  background: #f54097;
  color: #0f161b;
  text-shadow: none;
}

::selection {
  background: #f54097;
  color: #0f161b;
  text-shadow: none;
}

::-moz-placeholder {
  color: #adb0bc;
  font-size: 16px;
  opacity: 1;
}

::placeholder {
  color: #adb0bc;
  font-size: 16px;
  opacity: 1;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1490px;
}

.row {
  --bs-gutter-x: 30px;
}

.w-fit-content {
  width: -moz-fit-content;
  width: fit-content;
}

.glowingmain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: brightness(1.5);
  pointer-events: none;
}

.glowing {
  position: relative;
  min-width: 700px;
  height: 550px;
  margin: -150px;
  transform-origin: right;
  animation: colorChange 5s linear infinite;
}
.glowing:nth-child(even) {
  transform-origin: left;
}
.glowing span {
  position: absolute;
  top: calc(80px * var(--i));
  left: calc(80px * var(--i));
  bottom: calc(80px * var(--i));
  right: calc(80px * var(--i));
}
.glowing span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -8px;
  width: 6px;
  height: 6px;
  background: #f00;
  border-radius: 50%;
}
.glowing span:nth-child(3n+1) {
  animation: animate 16s alternate infinite;
}
.glowing span:nth-child(3n+1)::before {
  background: rgb(134, 255, 0);
  box-shadow: 0 0 20px rgb(134, 255, 0), 0 0 40px rgb(134, 255, 0), 0 0 60px rgb(134, 255, 0), 0 0 80px rgb(134, 255, 0), 0 0 0 8px rgba(134, 255, 0, 0.1);
}
.glowing span:nth-child(3n+2) {
  animation: animate-reverse 15s alternate infinite;
}
.glowing span:nth-child(3n+2)::before {
  background: rgb(255, 214, 0);
  box-shadow: 0 0 20px rgb(255, 214, 0), 0 0 40px rgb(255, 214, 0), 0 0 60px rgb(255, 214, 0), 0 0 80px rgb(255, 214, 0), 0 0 0 8px rgba(255, 214, 0, 0.1);
}
.glowing span:nth-child(3n+3) {
  animation: animate 25s alternate infinite;
}
.glowing span:nth-child(3n+3)::before {
  background: rgb(0, 226, 255);
  box-shadow: 0 0 20px rgb(0, 226, 255), 0 0 40px rgb(0, 226, 255), 0 0 60px rgb(0, 226, 255), 0 0 80px rgb(0, 226, 255), 0 0 0 8px rgba(0, 226, 255, 0.1);
}

.vx_border-button {
  font-weight: 700;
  background: linear-gradient(89.96deg, hsla(0, 0%, 100%, 0.05) 0.03%, hsla(0, 0%, 100%, 0.008) 49.67%, hsla(0, 0%, 100%, 0.05) 99.96%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding: 5px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  display: inline-block;
  color: #000;
}
.vx_border-button span {
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097, #3f26d9);
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  display: block;
}
.vx_border-button span::after {
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0.13), hsla(0, 0%, 100%, 0.13) 77%, hsla(0, 0%, 100%, 0.5) 92%, hsla(0, 0%, 100%, 0));
  content: "";
  height: 200%;
  left: -210%;
  opacity: 0;
  position: absolute;
  top: -50%;
  transition: all 0.7s ease 0s;
  width: 200%;
}
.vx_border-button ~ .vx_border-button {
  margin-left: 10px;
}
.vx_border-button i {
  margin-right: 10px;
}
.vx_border-button:hover span::after {
  left: -30%;
  opacity: 1;
  top: -20%;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-property: left, top, opacity;
  transition-timing-function: linear;
}

.vx_button.d-flex .vx_border-button {
  margin-bottom: 15px;
}

html.show-preloader body {
  display: none;
}

.faq-section {
  width: 80%;
  margin: 2rem auto;
  font-family: Arial, sans-serif;
}

.faq-list {
  list-style-type: none;
  padding: 0;
}

.faq-list li {
  border: 1px solid #ddd;
  margin: 1rem 0;
  padding: 1rem;
  transition: background-color 0.3s;
}

.faq-list li:hover {
  background-color: #f5f5f5;
}

.faq-list button {
  background: none;
  border: none;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  text-align: left;
  width: 100%;
  transition: color 0.3s;
}

.faq-list button:hover {
  color: #007bff;
}

.faq-list p {
  margin-top: 0.5rem;
}

.vx-fqtext h6 {
  font-size: 18px;
}

.vx-textp {
  font-size: 15px;
  line-height: 1.5;
}

.col-md-6:hover h6 {
  color: #686262;
}

.vx_section-title {
  position: relative;
}
.vx_section-title .vx_sub-title {
  padding: 7px 14px;
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097, #3f26d9);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border: 1px solid hsla(226, 71%, 84%, 0.801);
  border-radius: 100px;
  display: inline-block;
  margin-bottom: 22px;
  color: #ffffff;
}
.vx_section-title .vx_sub-title span {
  background: linear-gradient(264.28deg, #dec7ff -38.2%, #5c27fe 103.12%), #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
  background-clip: text;
  display: block;
}
.vx_section-title .title {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 1px;
  margin: 0 0;
}

.roadmapline {
  filter: invert(1) hue-rotate(76deg);
  max-width: 250px;
  width: 100%;
}

.vx_section-title.text-start .title {
  line-height: 1.17;
}
.vx_section-title.text-start::after {
  margin: 20px 0 0;
}

.transparent-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  height: auto;
}

.vx_header-main {
  padding: 11px 0;
  transition: all 0.4s ease-in-out;
}
.vx_header-main .mobile-nav-toggler {
  display: none;
  float: right;
  font-size: 26px;
  flex-direction: column;
  min-width: 45px;
  gap: 8px;
  cursor: pointer;
}
.vx_header-main .mobile-nav-toggler span {
  display: block;
  height: 3px;
  background-color: initial;
  background-image: linear-gradient(128deg, #f54097, #3f26d9);
  transition: all 0.3s ease-out 0s;
}
.vx_header-main .mobile-nav-toggler span:nth-child(1) {
  width: 26px;
}
.vx_header-main .mobile-nav-toggler span:nth-child(2) {
  width: 26px;
}
.vx_header-main .mobile-nav-toggler span:nth-child(3) {
  width: 26px;
}

.vx_header-main.vx_sticky-menu {
  padding: 0 0;
}

.vx_menu-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.vx_menu-navbar-wrap {
  display: flex;
  flex-grow: 1;
}
.vx_menu-navbar-wrap ul {
  display: flex;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
}
.vx_menu-navbar-wrap ul li {
  display: block;
  position: relative;
  list-style: none;
}
.vx_menu-navbar-wrap ul li a {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Comfortaa", sans-serif;
  padding: 38px 23px;
  display: block;
  line-height: 1;
  position: relative;
  letter-spacing: 0.8px;
  z-index: 1;
  text-transform: capitalize;
}
.vx_menu-navbar-wrap ul li .sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 230px;
  border: 1px solid #0f161b;
  background: #0f161b;
  margin: 0;
  transform: scale(1, 0);
  transform-origin: 0 0;
  transition: all 0.3s ease-out 0s;
  box-shadow: 0 30px 70px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0;
  padding: 18px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}
.vx_menu-navbar-wrap ul li .sub-menu .sub-menu {
  right: auto;
  left: 100%;
  top: 0;
}
.vx_menu-navbar-wrap ul li .sub-menu li {
  margin-left: 0;
  text-align: left;
  display: block;
}
.vx_menu-navbar-wrap ul li .sub-menu li a {
  padding: 9px 15px 9px 25px;
  line-height: 1.4;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  position: relative;
  display: flex;
}
.vx_menu-navbar-wrap ul li .sub-menu li a::before {
  content: "";
  display: block;
  width: 0;
  height: 7px;
  background-color: rgba(245, 64, 151, 0.3490196078);
  border-radius: 0 5px 0 0;
  margin: 7px 9px 0 0;
  transition: width 0.2s linear;
}
.vx_menu-navbar-wrap ul li .sub-menu li:hover > a::before {
  width: 7px;
}
.vx_menu-navbar-wrap ul li .sub-menu li.active > a::before {
  width: 7px;
}
.vx_menu-navbar-wrap ul li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.vx_menu-navbar-wrap > ul > li:hover > a {
  color: rgba(245, 64, 151, 0.3490196078);
}
.vx_menu-navbar-wrap > ul > li.active > a {
  color: rgba(245, 64, 151, 0.3490196078);
}

.vx_menu-main li.menu-item-has-children .dropdown-btn {
  display: none;
}

.vx_menu-action > ul {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.vx_menu-action > ul li {
  position: relative;
  margin-left: 25px;
}
.vx_menu-action > ul li:first-child {
  margin-left: 0;
}
.vx_menu-action > ul li a {
  color: #ffffff;
}
.vx_menu-action .search a {
  display: block;
  font-size: 20px;
}
.vx_menu-action .search a:hover {
  color: rgba(245, 64, 151, 0.3490196078);
}
.vx_menu-action .header-btn {
  padding-left: 25px;
}
.vx_menu-action .header-btn .btn {
  color: #0f161b;
  font-size: 14px;
  padding: 13px 22px;
  font-weight: 800;
}

.logo a {
  display: inline-block;
}
.logo img {
  max-width: 80px;
}

.vx_sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  transform: unset;
  z-index: 99;
  background: transparent;
  transition: all 0.4s ease;
  border-radius: 0;
  opacity: 1;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  border-bottom: 2px solid rgba(235, 64, 245, 0.2901960784);
}

.vx_sticky-menu.vx_sticky-menu-show {
  transform: unset;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.vx_mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding-right: 30px;
  max-width: 300px;
  height: 100%;
  z-index: 99;
  border-radius: 0;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) e;
  -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: translateX(-101%);
}
.vx_mobile-menu .navbar-collapse {
  display: block !important;
}
.vx_mobile-menu .nav-logo {
  position: relative;
  padding: 30px 25px;
  text-align: left;
}
.vx_mobile-menu .nav-logo img {
  max-width: 80px;
}
.vx_mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
  margin: 0;
  padding: 0;
}
.vx_mobile-menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid #0f161b;
}
.vx_mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 1.3;
  padding: 11px 60px 11px 25px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  transition: all 0.5s ease;
  border: none;
}
.vx_mobile-menu .navigation li > a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  transition: all 0.5s ease;
}
.vx_mobile-menu .navigation li ul li > a {
  margin-left: 20px;
}
.vx_mobile-menu .navigation li ul li ul li a {
  margin-left: 40px;
}
.vx_mobile-menu .navigation li ul li ul li ul li a {
  margin-left: 60px;
}
.vx_mobile-menu .navigation li > ul {
  display: none;
}
.vx_mobile-menu .navigation li > ul > li > ul {
  display: none;
}
.vx_mobile-menu .navigation li.current > a::before {
  height: 100%;
}
.vx_mobile-menu .navigation li.menu-item-has-children .dropdown-btn {
  position: absolute;
  right: 18px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  background: #0f161b;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.5s ease;
  z-index: 5;
}
.vx_mobile-menu .navigation li.menu-item-has-children .dropdown-btn .plus-line {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(0);
  border-radius: 10px;
  width: 12px;
  height: 2px;
  background-color: #ffffff;
  transition: all 0.5s ease;
}
.vx_mobile-menu .navigation li.menu-item-has-children .dropdown-btn .plus-line::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(0);
  border-radius: 10px;
  width: 2px;
  height: 12px;
  background-color: #ffffff;
  transition: all 0.5s ease;
}
.vx_mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open {
  background-color: rgba(245, 64, 151, 0.3490196078);
}
.vx_mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open .plus-line {
  background-color: #0f161b;
}
.vx_mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open .plus-line::after {
  display: none;
}
.vx_mobile-menu .navigation ul {
  padding: 0;
  margin: 0;
}
.vx_mobile-menu .navigation ul li a {
  display: block;
}
.vx_mobile-menu .navigation ul li ul li > a {
  font-size: 16px;
  margin-left: 20px;
  text-transform: capitalize;
}
.vx_mobile-menu .navigation:last-child {
  border-bottom: 1px solid #0f161b;
}
.vx_mobile-menu .navigation > li > ul > li:first-child {
  border-top: 1px solid #0f161b;
}
.vx_mobile-menu .close-btn {
  position: absolute;
  right: 15px;
  top: 28px;
  line-height: 1.2;
  width: 28px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  z-index: 10;
  transition: all 0.5s ease;
  padding: 4px;
}
.vx_mobile-menu .close-btn img {
  filter: hue-rotate(322deg);
}

.vx_social-links ul {
  display: flex;
  position: relative;
  padding: 11px 60px 11px 14px;
  flex-wrap: wrap;
  gap: 12px;
}
.vx_social-links ul li {
  position: relative;
  display: block;
}
.vx_social-links ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  font-size: 16px;
  color: #ffffff;
  transition: all 0.5s ease;
  border: 1px solid #0f161b;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  padding: 8px;
}
.vx_social-links ul li a:hover {
  border-color: rgba(245, 64, 151, 0.3490196078);
  background: #f54097;
  color: #f54097;
}

.vx_mobile-menu-bg {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -webkit-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
}

.vx_menu-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #0f161b;
  padding: 45px 0;
  z-index: 5;
  box-shadow: -9px 0 14px 0 rgba(0, 0, 0, 0.06);
}

.vx_mobile-menu-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  display: none;
  color: #ffffff;
  margin-right: 30px;
  top: 15px;
}

.mobile-menu-visible {
  overflow: hidden;
}
.mobile-menu-visible .vx_mobile-menu {
  transform: translateX(0);
}
.mobile-menu-visible .vx_mobile-menu .close-btn {
  transform: rotate(360deg);
}
.mobile-menu-visible .vx_mobile-menu-bg {
  opacity: 1;
  visibility: visible;
}

.span_title {
  color: #f54097;
}

.vx_banner-bg {
  background-position: center;
  background-size: cover;
  padding: 158px 0 54px;
  position: static;
  z-index: 1;
  overflow: hidden;
}

.vx_banner-content {
  margin: 50px 0 50px 0px;
  position: relative;
  z-index: 2;
}
.vx_banner-content h2 {
  text-transform: capitalize;
  font-size: 45px;
}
.vx_banner-content .title {
  text-transform: capitalize;
  margin: 0 0 21px;
  font-size: 150px;
  font-family: "Comfortaa", sans-serif;
  line-height: 0.8;
  font-weight: bold;
}
.vx_banner-content .title svg {
  width: 100%;
}
.vx_banner-content .title svg text {
  text-transform: uppercase;
  animation: stroke 5s infinite alternate;
  stroke-width: 3;
  stroke: #ffffff;
  font-size: 270px;
  fill: #845bff;
}
@keyframes stroke {
  0% {
    stroke: #ffffff;
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 3;
  }
  70% {
    stroke: #ffffff;
  }
  80% {
    stroke: #ffffff;
    stroke-width: 3;
  }
  100% {
    stroke: rgba(54, 95, 160, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 3;
  }
}
.vx_banner-content p {
  margin: 0 0;
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  line-height: 1.4;
  color: #ffffff;
}

.vx_button {
  margin: 35px 0 0;
}

.vx_banner-img img {
  margin: 0 auto;
  position: relative;
  z-index: 1;
  margin-top: 70px;
  width: 300px;
  mix-blend-mode: difference;
  transform: scale(1.7);
  transform-origin: 60% center;
}
.vx_banner-img img.roundBg {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
  animation: hue 3s infinite;
  -webkit-animation: hue 3s infinite;
}

.vx_banner::after {
  left: auto;
  right: 0;
  -webkit-clip-path: polygon(100% 0, 0 100%, 100% 100%);
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.vx_statistical-item {
  padding: 0px 15px 15px;
  margin: 0 0 30px;
  text-align: center;
  height: 100%;
  background: transparent;
  border-radius: 8px;
  transition: 0.7s;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0px 0px 40px -20px #ffffff;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.speciality-box {
  text-align: center;
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.05), hsla(0, 0%, 100%, 0) 73.86%);
  position: relative;
  padding: 65px 20px 10px;
  border-radius: 15px;
  margin-top: 75px;
  height: calc(100% - 75px);
}
.speciality-box .icon {
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%);
  width: 105px;
  height: 105px;
  min-width: 105px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease-in-out;
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.speciality-box h5 {
  text-transform: capitalize;
}
.speciality-box .number {
  color: #fff;
  transition: all 0.3s ease-in-out;
  font-size: 50px;
  margin-top: 20px;
  margin-bottom: 0px;
  position: sticky;
  top: 100%;
}
.speciality-box:hover .icon {
  transform: translate(-50%, -50%) rotateY(-1turn);
}
.speciality-box:hover .number {
  color: #fff;
}

.area-background {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-pt-95 {
  padding-top: 95px;
}

.section-pb-120 {
  padding-bottom: 120px;
}

.streamers-active {
  margin: 0 -15px 0;
}

.streamers__item {
  border: 3px solid #0f161b;
  text-align: center;
  border-radius: 10px;
  padding: 3px;
  margin: 0 0 30px;
  position: relative;
  transition: all 0.3s ease-out 0s;
}
.streamers__item:hover {
  border-color: rgba(245, 64, 151, 0.3490196078);
}
.streamers__item:hover .streamers__thumb::before {
  opacity: 0;
}
.streamers__item:hover .streamers__thumb::after {
  height: 100%;
  opacity: 0.8;
}

.streamers__thumb {
  position: relative;
  z-index: 1;
}
.streamers__thumb::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: #0f161b;
  background: linear-gradient(0deg, #0f161b 0, rgba(255, 255, 255, 0) 100%);
  opacity: 1;
  pointer-events: none;
  transition: all 0.4s ease-out 0s;
}
.streamers__thumb::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  border-radius: 6px;
  background: #f54097;
  background: linear-gradient(0deg, #f54097 0, transparent 100%);
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease-out 0s;
}
.streamers__thumb img {
  border-radius: 6px;
  border: 1px solid #0f161b;
  width: 100%;
}

.streamers__content {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 28px;
  pointer-events: none;
  z-index: 1;
}
.streamers__content .name {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 0;
}

.section-pb-85 {
  padding-bottom: 85px;
}

.section-pt-85 {
  padding-top: 85px;
}

.section-pb-150 {
  padding-bottom: 150px;
}

.section-pb-50 {
  padding-bottom: 50px;
}

.faqsec {
  padding-top: 50px;
}

.faq_header-title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 30px;
}

.faq__panel {
  padding: 7px 21px;
  margin-bottom: 24px;
  border: 1px solid rgba(160, 100, 225, 0.4509803922);
  box-shadow: inset 0px 20px 20px -20px rgba(232, 172, 253, 0.8509803922);
  border-radius: 7px;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
}

.faq__label {
  padding-block: 7px;
  color: #f4f5f6;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.faq__label:hover {
  background-image: linear-gradient(128deg, #f32ed9, #a99df5);
  text-shadow: none;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.faq__panel-answer {
  color: #fff;
  padding-top: 5px;
  padding-bottom: 7px;
}

.aboutussec {
  padding-top: 70px;
}

.aboutvid {
  width: 100%;
  height: auto;
  max-width: 657px !important;
  mix-blend-mode: exclusion;
}

.aboutusimg img {
  width: 100%;
  height: auto;
  max-width: 370px !important;
  transform: scaleX(-1);
}
.aboutusimg img.erngimg {
  max-width: 320px;
  padding: 20px;
}

.vx_ecosystem-content .title {
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1.2px;
  margin: 0 0 18px;
}
.vx_ecosystem-content .title a:hover {
  color: rgba(245, 64, 151, 0.3490196078);
}
.vx_ecosystem-content p {
  font-size: 15px;
  font-weight: 500;
  margin: 0 0;
  line-height: 1.2;
}

.vx_ecosystem-images {
  margin: 0 auto;
  position: relative;
  height: auto;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: static;
  display: block;
}
.vx_ecosystem-images img {
  width: 100%;
  max-width: 320px;
  mix-blend-mode: lighten;
}

.vx_services-images {
  mix-blend-mode: lighten;
}

.vx_footer {
  background: #0f161b;
  padding: 20px 0;
  box-shadow: inset 0px 10px 20px -20px #ffffff;
  position: relative;
}

.vx_footer-copyright-text p {
  margin: 0 0;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Comfortaa", sans-serif;
  color: #adb0bc;
}
.vx_footer-copyright-text p span {
  color: #f54097;
}

.vx_animate-text.style2 span {
  animation: 0.8s both vx_animated-text2;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: #ffffff;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

.ps-timeline-sec {
  position: relative;
}
.ps-timeline-sec .container {
  position: relative;
}
.ps-timeline-sec .container ol:before {
  background: #bb7b27;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  left: 8px;
  top: -3.5px;
}
.ps-timeline-sec .container ol:after {
  background: #bb7b27;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  right: 8px;
  top: -3.5px;
}
.ps-timeline-sec .container ol.ps-timeline {
  margin: 240px 0;
  padding: 0;
  border-top: 2px solid #bb7b27;
  list-style: none;
}
.ps-timeline-sec .container ol.ps-timeline li {
  float: left;
  width: 25%;
  padding-top: 30px;
  position: relative;
}
.ps-timeline-sec .container ol.ps-timeline li span {
  width: 70px;
  height: 70px;
  margin-left: -25px;
  background: #010104;
  border: 2px solid #bb7b27;
  border-radius: 50%;
  box-shadow: 0 0 0 0px #0f161b;
  text-align: center;
  line-height: 1.1;
  color: #ffffff;
  font-size: 26px;
  font-style: normal;
  position: absolute;
  top: -40px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
  content: "";
  color: rgba(245, 64, 151, 0.3490196078);
  width: 2px;
  height: 50px;
  background: #bb7b27;
  position: absolute;
  top: -50px;
  left: 50%;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
  content: "";
  color: rgba(245, 64, 151, 0.3490196078);
  width: 8px;
  height: 8px;
  background: #bb7b27;
  position: absolute;
  bottom: 108px;
  left: 46%;
  border-radius: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
  content: "";
  color: rgba(245, 64, 151, 0.3490196078);
  width: 2px;
  height: 50px;
  background: #bb7b27;
  position: absolute;
  bottom: -50px;
  left: 50%;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
  content: "";
  color: rgba(245, 64, 151, 0.3490196078);
  width: 8px;
  height: 8px;
  background: #bb7b27;
  position: absolute;
  top: 108px;
  left: 46%;
  border-radius: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
  position: absolute;
  bottom: 0;
  margin-bottom: 130px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: #ffffff;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
  display: table;
  margin: 0 auto;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
  position: absolute;
  margin-top: 60px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: #ffffff;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
  display: table;
  margin: 0 auto;
}
.ps-timeline-sec .container ol.ps-timeline li b {
  line-height: 1.2;
  text-align: left;
  margin-bottom: 10px;
  background-color: transparent;
  display: block;
  padding: 8px 10px;
  font-weight: 400;
  color: #ffffff;
  font-size: 16px;
  border: 1px dashed #bb7b27;
  border-radius: 4px;
}
.ps-timeline-sec .container ol.ps-timeline li p {
  width: 100%;
  margin: 0 auto 5px;
  line-height: 1.2;
  text-align: left;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-top {
  position: absolute;
  bottom: 0;
  margin-bottom: 100px;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-bot {
  position: absolute;
  margin-top: 35px;
}

.vx_polygonmain .create__main {
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%);
  border-radius: 26px;
  position: relative;
}
.vx_polygonmain .create__main .content {
  position: relative;
  z-index: 1;
  padding: 32px 30px 29px;
  background-color: transparent;
  height: auto;
}
.vx_polygonmain .vx_section-title::after {
  display: none;
}
.vx_polygonmain .vx_section-title img {
  max-width: 180px;
  margin: 20px auto;
}

.vx_tokenomicsmain .vx_tokenomicsmain_images {
  max-width: 100%;
  text-align: center;
}
.vx_tokenomicsmain .vx_tokenomicsmain_images img {
  width: 100%;
  max-width: 600px;
}
.vx_tokenomicsmain ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.vx_tokenomicsmain ul li {
  margin: 0px 5px 10px;
  padding: 8px 8px 8px 15px;
  box-shadow: inset 0px 0px 10px -5px #ffffff;
  border-radius: 7px;
  font-size: 15px;
  flex: 0 0 calc(50% - 10px);
  max-width: calc(50% - 10px);
  border-bottom: 4px solid #ffffff;
}
.vx_tokenomicsmain ul li:nth-child(1) {
  border-color: #41ff71;
}
.vx_tokenomicsmain ul li:nth-child(2) {
  border-color: #41c3ff;
}
.vx_tokenomicsmain ul li:nth-child(3) {
  border-color: #ffffff;
}
.vx_tokenomicsmain ul li:nth-child(4) {
  border-color: rgba(245, 64, 151, 0.3490196078);
}
.vx_tokenomicsmain ul li:nth-child(5) {
  border-color: #e941ff;
}
.vx_tokenomicsmain ul li:nth-child(6) {
  border-color: #41ff71;
}
.vx_tokenomicsmain ul li:nth-child(7) {
  border-color: #41aaff;
}
.vx_tokenomicsmain ul li:nth-child(8) {
  border-color: #e941ff;
}
.vx_tokenomicsmain ul li:nth-child(9) {
  border-color: rgba(245, 64, 151, 0.3490196078);
}
.vx_tokenomicsmain ul li:nth-child(10) {
  border-color: #41c3ff;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.heading-box,
.value-box {
  flex: 1;
  padding: 0.5em;
  border: 1px solid #ccc;
  margin: 0.2em;
  box-sizing: border-box;
}

.heading-box {
  font-weight: bold;
  background-color: #f4f4f4;
}

.vx_statistical .vx_statistical-item {
  margin: 0 auto 0px;
  padding: 20px 10px;
}
.vx_statistical .vx_statistical-item h6 {
  font-size: 26px;
}
.vx_statistical .vx_statistical-item p {
  font-size: 20px;
}

.vx_footer-social {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  justify-content: flex-end;
}
.vx_footer-social a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vx_footer-social img {
  height: 25px;
  width: auto;
}
.vx_footer-social .twitter {
  filter: invert(1);
}
.vx_footer-social a.backtotop {
  position: absolute;
  top: -55px;
  right: 40px;
  transform: scale(1.5);
}
.vx_footer-social a.backtotop img {
  border-radius: 100px;
  border: 1px solid #ffffff;
}

@media (max-width: 765px) {
  .tokenomicstable h2 {
    text-align: center;
  }
  .vx_footer-copyright-text p {
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .vx_footer-social a.backtotop {
    top: -10px;
    right: 12px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
@media (max-width: 1500px) {
  .container {
    max-width: 1490px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .vx_menu-navbar-wrap ul {
    margin: 0 auto;
  }
  .vx_banner-content {
    margin: 35px 0 50px 0px;
  }
  .vx_banner-content .title {
    font-size: 118px;
  }
}
@media (max-width: 1199.98px) {
  .vx_faq-content .vx_section-title p br {
    display: none;
  }
  .vx_faq-wrapper .accordion-item {
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
  .vx_faq-wrapper .accordion-body {
    padding: 15px 0 0 0;
  }
  .vx_section-title .title br {
    display: none;
  }
  .vx_header-main.vx_sticky-menu {
    padding: 12px 0;
  }
  .vx_header-main {
    padding: 25px 0;
  }
  .vx_header-main .mobile-nav-toggler {
    display: flex;
    margin-top: 20px;
  }
  .vx_menu-nav {
    justify-content: space-between;
  }
  .vx_menu-action {
    margin-right: 40px;
  }
  .vx_menu-action .header-btn::before {
    box-shadow: inset 0 3px 7px 0 #f54097;
    opacity: 0.1;
  }
  .vx_banner-content .vx_sub-title {
    font-size: 16px;
  }
  .vx_banner-content .title {
    font-size: 94px;
  }
  .vx_statistical-item {
    margin: 0 auto 30px;
  }
  .services__wrapper {
    gap: 50px 45px;
  }
  .services__wrapper .vx_ecosystem-item:nth-child(3) {
    margin: 0;
  }
  .services__wrapper .vx_ecosystem-item:nth-child(2) {
    margin: 0px 0 0;
  }
}
@media (max-width: 991.98px) {
  .vx_faq-wrapper .accordion-body {
    padding: 11px 0 0 45px;
  }
  .vx_banner-bg {
    padding: 125px 0 55px;
  }
  .vx_banner-content {
    margin: 0 0 70px;
    text-align: center;
  }
  .vx_banner-content .vx_sub-title {
    font-size: 18px;
  }
  .vx_banner-content .title {
    font-size: 120px;
  }
  .vx_banner-img img {
    margin: 10px auto;
    max-width: 380px;
    width: 100%;
    display: block;
    transform: scale(1);
  }
  .vx_statistical-item-image img {
    max-height: 170px;
  }
  .vx_ecosystem-main .vx_section-title {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .container {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .vx_faq-wrapper .accordion-body {
    padding: 15px 0 0 0;
  }
  .vx_section-title .vx_sub-title {
    margin: 0 0 10px;
  }
  .vx_section-title .title {
    font-size: 35px;
    line-height: 1.1;
  }
  .transparent-header {
    top: 0;
  }
  .vx_header-main {
    padding: 20px 0;
  }
  .vx_header-main .mobile-nav-toggler {
    margin-top: 20px;
  }
  .vx_banner-content {
    margin: 0 0 50px;
  }
  .vx_banner-content .vx_sub-title {
    font-size: 17px;
  }
  .vx_banner-content .title {
    font-size: 18vw;
    text-shadow: -1px 3px 0 #f54097;
  }
  .vx_ecosystem-main .vx_section-title {
    text-align: center !important;
  }
  .vx_ecosystem-main .vx_section-title::after {
    margin: 20px auto 0;
  }
  .services__wrapper {
    text-align: center;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  .vx_footer-copyright-text {
    text-align: center;
  }
  .vx_footer-card {
    margin-top: 10px;
  }
}
html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

.attribution {
  font-size: 11px;
  text-align: center;
  background-color: hsl(240, 5%, 91%);
  padding: 8px 0 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.attribution a {
  color: hsl(228, 45%, 44%);
}

.card-wrapper {
  padding-bottom: 50px;
  padding-top: 25px;
}

.content-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-card {
  background: linear-gradient(264.28deg, #dec7ff -38.2%, #5c27fe 103.12%);
  border-radius: 20px;
  margin-top: 90px;
  position: relative;
  box-shadow: 0px 60px 50px -25px rgba(0, 0, 0, 0.35);
  z-index: 1;
}
.faq-card::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: -9;
}

.faq-ilustrations {
  position: relative;
}

.faq-ilustrations .mobile.ilust {
  position: absolute;
  top: -110px;
  left: -30px;
  right: 0;
}

.mobile {
  display: block;
  margin: 0 auto;
}

.faq-content {
  padding: 9px 25px 3rem;
}

.faq-content h1 {
  font-size: 32px;
  text-align: center;
  color: #fff;
}

.faq-accordion {
  padding: 8px 0;
  border-bottom: 1px solid hsl(240, 5%, 91%);
}

input.tgg-title {
  -webkit-appearance: unset;
     -moz-appearance: unset;
          appearance: unset;
  all: unset;
}

.faq-accordion-title label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.faq-accordion-title h2 {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}

.faq-accordion-title span {
  margin-left: auto;
  transition: transform 0.4s ease-in-out;
}

.arrow-icon svg path {
  fill: #fff;
}

.faq-accordion-content {
  color: gray;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-in-out;
}
.faq-accordion-content p {
  color: #e0e7ff;
  text-align: left;
}

.faq-accordion-title:hover h2 {
  color: var(--primary-soft-color);
}

.faq-accordion .tgg-title:checked + div > label > h2 {
  font-weight: 700;
}

.faq-accordion .tgg-title:checked + div > label > span {
  will-change: transform;
  transform: rotate(180deg);
}

.faq-accordion .tgg-title:checked ~ .faq-accordion-content {
  will-change: max-height;
  max-height: 80px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .vx_header-main {
    padding: 25px 0;
  }
  .vx_banner-content .title {
    font-size: 17vw;
    text-shadow: -1px 5px 0 #f54097;
  }
  .vx_statistical-item-image img {
    max-height: 249px;
  }
  .services__wrapper {
    gap: 50px 40px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1800px) {
  .vx_banner-content {
    margin: 35px 0 0px 0px;
  }
}
@media (max-width: 1500.98px) {
  .vx_menu-navbar-wrap ul li a {
    font-size: 14px;
    padding: 30px 14px;
  }
}
@media (max-width: 768px) {
  .content {
    height: 30vh;
  }
  .topmainsecai .infotext h1 {
    font-size: 24px;
  }
}
@media screen and (max-width: 767px) {
  .vx_faq {
    padding: 60px 0 60px;
  }
  .ps-timeline-sec .container ol:before {
    background: #f54097;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 130px !important;
    left: 36px !important;
  }
  .ps-timeline-sec .container ol:after {
    background: #f54097;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    right: 8px;
    top: -3.5px;
  }
  .ps-timeline-sec .container ol.ps-timeline {
    margin: 0px 0 !important;
    border-left: 2px solid #f54097;
    padding-left: 0 !important;
    padding-top: 120px !important;
    border-top: 0 !important;
    margin-left: 25px !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    height: 140px;
    float: none !important;
    width: inherit !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li:nth-child(2) .img-handler-bot img {
    width: 70px;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child {
    margin: 0;
    bottom: 0 !important;
    height: 120px;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot {
    bottom: 40px !important;
    width: 40% !important;
    margin-left: 25px !important;
    margin-top: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot img {
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span {
    left: 0 !important;
    width: 40px;
    height: 40px;
    margin-left: -21px;
    font-size: 16px;
    top: -15px;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
    position: absolute !important;
    bottom: 150px !important;
    width: 30% !important;
    float: left !important;
    margin-left: 35px !important;
    margin-bottom: 0 !important;
    display: none;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
    margin: 0 auto !important;
    width: 80% !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
    position: absolute !important;
    bottom: 115px !important;
    width: 30% !important;
    float: left !important;
    margin-left: 35px !important;
    margin-bottom: 0 !important;
    display: none;
  }
  .ps-timeline-sec .container ol.ps-timeline li p {
    text-align: left !important;
    width: 100% !important;
    margin: 0 auto !important;
    margin-top: 0px !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    width: 85% !important;
    float: right !important;
    right: 0;
    top: -60px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
    width: 85% !important;
    float: right !important;
    right: 0;
    top: -60px;
    margin-top: 0px;
  }
  .ps-timeline-sec .container ol.ps-timeline li b {
    display: inline-block;
  }
  .section-pb-85 {
    padding-bottom: 50px;
  }
  .section-pt-85 {
    padding-top: 50px;
  }
  .section-pb-150 {
    padding-bottom: 85px;
  }
  .section-pb-50 {
    padding-bottom: 30px;
  }
  .section-pt-50 {
    padding-top: 30px;
  }
  .vx_footer-social {
    gap: 30px 10px;
    margin-top: 20px;
    justify-content: center;
  }
  .loader__container__counter {
    font-size: 50px;
  }
  .loader__container__loading {
    font-size: 20px;
  }
  .vx_faq-wrapper .accordion-button {
    font-size: 16px;
  }
}
.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  mix-blend-mode: lighten;
}

.plan-area {
  padding: 10px 0;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  padding-top: 50px;
  padding-bottom: 50px;
}

.planbg {
  position: absolute;
  top: -300px;
  max-width: 1000px;
  z-index: -1;
  transform: rotate(84deg);
}

.plantittlesection {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.plancardsection {
  display: flex;
}

.plancard {
  height: auto;
  padding: 20px;
  margin-right: 20px;
  border-radius: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.plancard h3 {
  border: 1px solid gray;
  border-radius: 7px;
  width: 100%;
  height: auto;
  max-width: 200px;
  font-size: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #38a081;
  text-align: center;
  padding: 2px;
}
.plancard img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.react-player video {
  max-width: 100%;
}

.button-prev,
.button-next {
  width: 50px;
  height: 50px;
  background-color: #333;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.button-prev:hover,
.button-next:hover {
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%);
}

.icon {
  width: 24px;
  height: 24px;
}

.plantittlesection {
  display: flex;
  justify-content: space-between; /* Adjust as needed to give space between the title and buttons */
  align-items: center;
}

.planbutton {
  display: flex;
  gap: 10px; /* Provides space between the buttons */
}

.plan-btn-grp {
  display: flex;
  justify-content: center;
  align-items: center;
}
.plan-btn-grp a {
  padding: 15px 22px;
  border-radius: 16px;
  border: 1px solid transparent;
  color: #000;
  background: linear-gradient(264.28deg, #dec7ff -38.2%, #5c27fe 103.12%);
  font-size: 16px;
  letter-spacing: 1px;
  transition: all 0.15s linear;
  margin-right: 20px;
  text-decoration: none;
}
.plan-btn-grp a:hover {
  background-color: rgba(29, 201, 160, 0.08);
  border-color: #5c27fe;
  color: #5c27fe;
  transform: translateY(-5px) scale(1.05);
}
.plan-btn-grp .fontfarrow {
  transform: rotate(45deg);
  margin-left: 10px;
}
.plan-btn-grp .fontfa {
  margin-right: 10px;
}

.banneranimation {
  align-items: center;
  justify-items: center;
  margin: auto;
  padding-bottom: 67px;
}
.banneranimation .marquee {
  height: 300px;
  width: 100%;
}
.banneranimation .marquee.paused {
  animation-play-state: paused;
}

@media screen and (max-width: 1200px) {
  section {
    width: 100%;
  }
}
.myvideo {
  text-decoration: none;
  color: #fff;
  background: red;
  text-align: center;
  padding: 10px;
  font-weight: 600;
  z-index: 100;
  position: absolute;
  bottom: 20px;
}

.myvideo:hover {
  color: #000;
}

.hexagon {
  height: 300px; /* Adjust as per your requirement */
  display: inline-block;
  margin-right: 30px;
  background-color: #000;
  position: relative;
  border-radius: 20px;
}
.hexagon .animationimg {
  width: 100%;
  height: auto;
  position: relative;
}
.hexagon .animationimg img {
  max-width: 300px;
}

.marquee-animation {
  scroll-behavior: auto;
}

.marquee-paused {
  scroll-behavior: pause;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
.marquee-content {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 25s linear infinite;
  /* 10s is the duration of one cycle; adjust as needed */
}

.marquee-container:hover .marquee-content {
  animation-play-state: paused;
}

.designcontainer {
  position: relative;
}

.lablesection {
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  height: 150px;
  left: -10px;
  overflow: hidden;
  top: -10px;
  width: 150px;
  z-index: 20;
}

.lablesection:before {
  align-items: center;
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%);
  color: #fff;
  content: "";
  display: flex;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.1em;
  position: absolute;
  text-transform: uppercase;
  transform: rotate(-45deg) translateY(-20px);
  width: 150%;
}

.lablesection:after {
  background-image: linear-gradient(264.28deg, rgb(255, 219, 128) -38.2%, rgb(95, 73, 38) 103.12%);
  bottom: 0;
  box-shadow: 140px -140px rgb(95, 73, 38);
  content: "";
  height: 10px;
  left: 0;
  position: absolute;
  width: 11px;
  z-index: -1;
}

.label {
  position: absolute;
  height: 30px;
  left: -17%;
  top: 30%;
  transform: rotate(-45deg);
  width: 170px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .alice-carousel__dots {
    display: none;
  }
  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .plancardsection {
    display: block;
  }
  .plancard {
    margin-bottom: 20px;
  }
  .planbutton {
    display: none;
  }
  .plantitle {
    margin-bottom: 0px;
    text-align: center;
  }
  .vx_section-title.text-left {
    text-align: center;
  }
  .vx_banner-content h2 {
    font-size: 28px;
  }
}
@media screen and (max-width: 425px) {
  .plancard {
    margin-right: 0;
  }
}
@media screen and (max-width: 992px) {
  .vx_ecosystem-images {
    text-align: center;
    margin-top: 50px;
  }
  .vx_section-title .title {
    text-align: center;
  }
  .title {
    text-align: center;
  }
  .title + p {
    text-align: justify;
  }
  .vx_section-title.text-start::after {
    margin: 20px auto 0;
  }
  section.services-area.vx_ecosystem-main {
    padding-bottom: 0px;
  }
  section.services-area.vx_ecosystem-main .vx_services-images {
    padding-top: 50px;
  }
  .vx_button.d-flex.flex-wrap {
    justify-content: center;
  }
}
@media screen and (max-width: 765px) {
  p {
    text-align: center;
  }
}
@media screen and (max-width: 575px) {
  .vx_banner-bg {
    padding: 80px 0 20px;
  }
  .vx_section-title .title {
    font-size: 26px;
  }
  .services__wrapper .vx_ecosystem-item:nth-child(2) {
    margin: -9px 0 0;
  }
  .services__wrapper {
    text-align: center;
    grid-template-columns: repeat(1, 1fr);
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
  }
  .speciality-box .number {
    font-size: 22px;
  }
}
@media screen and (max-width: 390px) {
  .vx_tokenomicsmain h2 {
    text-align: center;
  }
  .vx_tokenomicsmain ul li {
    margin: 0px 0px 10px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .vx_border-button {
    padding: 8px 4px;
    font-size: 14px;
    min-width: 140px;
    min-height: 40px;
  }
}/*# sourceMappingURL=style.css.map */